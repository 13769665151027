function sameAddress() {
    if ( $('[name="same-address"]:checked').val() === 'yes' ) {
        $('.billing-address').slideUp(350);
    } else {
        $('.billing-address').slideDown(350);
    }
    addTokenizerData();
}

function mailingAddress() {
    if( $('[name="ship_country"]').val() !== 'US') {
        $('.mailing-address .non-usa').show();
        $('.mailing-address .usa').hide();
        $('[name="ship_state"]').removeAttr('required');
        $('[name="ship_providence"]').attr('required', true);
        $('[name="ship_zip"]').removeAttr('maxlength');
        $('[name="ship_zip"]').removeAttr('pattern');
    } else {
        $('.mailing-address .non-usa').hide();
        $('.mailing-address .usa').show();
        $('[name="ship_state"]').attr('required', true);
        $('[name="ship_providence"]').removeAttr('required');
        $('[name="ship_zip"]').attr('maxlength', 5);
        $('[name="ship_zip"]').attr('pattern', '[0-9]*');
    }
    addTokenizerData();
}

function billingAddress() {
    if( $('.biling-country').val() !== 'US') {
        $('.billing-address .non-usa').show();
        $('.billing-address .usa').hide();
        $('.billingState').removeAttr('required').removeAttr('data-tokenizer-field');
        $('.billinProvidence').attr('required', true).attr('data-tokenizer-field', 'state');
        $('[name="billing-code"]').removeAttr('maxlength');
        $('[name="billing-code"]').removeAttr('pattern');
    } else {
        $('.billing-address .non-usa').hide();
        $('.billing-address .usa').show();
        $('.billinProvidence').removeAttr('required').removeAttr('data-tokenizer-field');
        $('.billingState').attr('required', true).attr('data-tokenizer-field', 'state');
        $('[name="billing-code"]').attr('maxlength', 5);
        $('[name="billing-code"]').attr('pattern', '[0-9]*');
    }
    addTokenizerData();
}

function addTokenizerData(){
    // $('body').find('[data-tokenizer-field="line1"]').removeAttr('data-tokenizer-field');
    // $('body').find('[data-tokenizer-field="line2"]').removeAttr('data-tokenizer-field');
    // $('body').find('[data-tokenizer-field="city"]').removeAttr('data-tokenizer-field');
    // $('body').find('[data-tokenizer-field="state"]').removeAttr('data-tokenizer-field');
    // $('body').find('[data-tokenizer-field="zipcode"]').removeAttr('data-tokenizer-field');
    // $('body').find('[data-tokenizer-field="country"]').removeAttr('data-tokenizer-field');

    // if( !$('[name="same-address"]').is(':checked') ){
    // 	$('[name="billing-country"]').attr('data-tokenizer-field', 'country');
    // 	$('[name="billing-address"]').attr('data-tokenizer-field', 'line1');
    // 	$('[name="billing-city"]').attr('data-tokenizer-field', 'city');
    // 	$('[name="billing-code"]').attr('data-tokenizer-field', 'zipcode');

    // 	if( $('[name="billing-country"]').val() === "US" ){
    // 		$('[name="billing-state"]').attr('data-tokenizer-field', 'state');
    // 	} else {
    // 		$('[name="billing-providence"]').attr('data-tokenizer-field', 'state');
    // 	}
    // } else {
    // 	$('[name="mailing-country"]').attr('data-tokenizer-field', 'country');
    // 	$('[name="mailing-address"]').attr('data-tokenizer-field', 'line1');
    // 	$('[name="mailing-city"]').attr('data-tokenizer-field', 'city');
    // 	$('[name="mailing-code"]').attr('data-tokenizer-field', 'zipcode');

    // 	if( $('[name="mailing-country"]').val() === "US" ){
    // 		$('[name="mailing-state"]').attr('data-tokenizer-field', 'state');
    // 	} else {
    // 		$('[name="mailing-state-foreign"]').attr('data-tokenizer-field', 'state');
    // 	}
    // }
}

function passwordStrength() {
    if( $('[name="password"]').length ){
        var password = $('[name="password"]').val();
        var isLongEnough = password.length >= 8;
        if(isLongEnough){
            $('.length').removeClass('failure').addClass('success');
        } else {
            $('.length').removeClass('success').addClass('failure');
        }

        var isMixedCase = (password.toUpperCase() !== password) && (password.toLowerCase() !== password);
        if(isMixedCase){
            $('.mixed-case').removeClass('failure').addClass('success');
        } else {
            $('.mixed-case').removeClass('success').addClass('failure');
        }

        var containsNumbers = !!password.match(/\d/);
        if(containsNumbers){
            $('.has-numbers').removeClass('failure').addClass('success');
        } else {
            $('.has-numbers').removeClass('success').addClass('failure');
        }

        var containsSpecialCharacters = !!password.match(/[^a-z0-9]/i);
        if(containsSpecialCharacters){
            $('.has-special').removeClass('failure').addClass('success');
        } else {
            $('.has-special').removeClass('success').addClass('failure');
        }

        var valid = isLongEnough && isMixedCase && containsNumbers && containsSpecialCharacters;
        if(valid === false && $('.password-strength').hasClass('password-check') ) {
            $('.password-strength').slideDown();
            return false;
        } else if(valid === true){
            $('.password-strength').slideUp();
            return true;
        } else {
            $('.password-strength').slideDown();
            return true;
        }
    }
}

function notifyAttorney(){
    if( $('[name="notify-attorney"]').val() === 'yes' ){
        $('.attorney-email').show().find('input').attr('required', true);
    } else {
        $('.attorney-email').hide().find('input').removeAttr('required');
    }
}

var LuhnCheck = (function() {
    var luhnArr = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9];
    return function(str) {
        var counter = 0;
        var incNum;
        var odd = false;
        var temp = String(str).replace(/[^\d]/g, "");
        if ( temp.length === 0) {
            return false;
        }
        for (var i = temp.length-1; i >= 0; --i) {
            incNum = parseInt(temp.charAt(i), 10);
            counter += (odd = !odd)? incNum : luhnArr[incNum];
        }
        return (counter%10 === 0);
    };
})();

function unmaskInput() {
    $('[type="tel"]').each(function(){
        if( $(this).siblings('.intl-number').find('input').is(':checked') ) {
            $(this).unmask();
        } else {
            $(this).mask('(999) 999-9999');
        }
    });
}

$(function(){
    // initialize Foundation first with check
    if (typeof Foundation !== 'undefined') {
        $(document).foundation();

        Foundation.Abide.defaults.validators['corporation'] =
            function($el, required, parent) {
                var entType = $('[name="entity-type"]:checked').val();
                if( entType == 'Corp' ) {
                    $el.attr('required', true);
                    if( $el.val() == '' || $el.val() == null) {
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    $el.removeAttr('required');
                    return true;
                }
            };

        Foundation.Abide.defaults.validators['llc'] =
            function($el, required, parent) {
                var entType = $('[name="entity-type"]:checked').val();
                if( entType == 'LLC' ) {
                    $el.attr('required', true);
                    if( $el.val() == '' || $el.val() == null) {
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    $el.removeAttr('required');
                    return true;
                }
            };

        Foundation.Abide.defaults.validators['sameAddress'] =
            function($el, required, parent) {
                if( $('[name="same-address"]:checked').val() !== 'yes' ) {
                    $el.attr('required', true);
                    if( $el.val() == '') {
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    $el.removeAttr('required');
                    return true;
                }
            };

        Foundation.Abide.defaults.validators['cardNumber'] =
            function($el, required, parent) {
                var value = $el.val();
                if( $el.val().length >= 15 ){
                    return LuhnCheck(value);
                } else {
                    return false;
                }
            };

        Foundation.Abide.defaults.validators['strongPassword'] =
            function($el, required, parent) {
                return passwordStrength();
            };

        Foundation.Abide.defaults.validators['equalTo'] =
            function($el, required, parent) {
                var id = $el.attr('data-equalTo');
                if( $('#' + id).val() === $el.val() ) {
                    return true;
                } else {
                    return false;
                }
            };

        var myform = $('form[data-abide]#signupform');
        var myoptions = {
            liveValidate: true,
        };

        var abideForm = new Foundation.Abide(myform, myoptions);
    } else {
        console.warn('Foundation is not loaded');
    }

    $('[type="tel"]').mask('(999) 999-9999');

    unmaskInput();
    $('.intl-number input').on('click', function(){
        unmaskInput();
    });

    $('.billing-address').hide();
    sameAddress();
    $('[name="same-address"]').on('change', function(){
        sameAddress();
    });

    mailingAddress();
    $('[name="ship_country"]').on('change', function() {
        mailingAddress();
    });

    billingAddress();
    $('.biling-country').on('change', function() {
        billingAddress();
    });

    passwordStrength();
    $('[name="password"]').on('keyup', function(){
        passwordStrength();
    });

    $('[name="password"]').on('focusout', function(){
        $('.password-strength').addClass('password-check');
        passwordStrength();
    });

    notifyAttorney();
    $('[name="notify-attorney"]').on('change', function(){
        notifyAttorney();
    });


    $('#signupform.pay [type="submit"]').on('click', function(event){
        event.preventDefault();
        $('.password-strength').addClass('password-check');
        $('#signupform').find('input, textarea, select').each(function(){
            $('#signupform').foundation('validateInput', $(this));
        });

        if( $('.is-invalid-input').length > 0 ){
            $('.is-invalid-input').first().focus();
        } else {
            if(!window.console){
                console={};
                console.log = function(){};
            }
            var form = $('#signupform.pay');
            try{
                Tokenizer.createCard(form, function(card) {
                    var token = card.getToken();

                    form.append($('<input type="hidden" name="token" />').val(token));
                    $('.card-errors').hide();
                    $('#signupform .processing').show();
                    $('#signupform [type="submit"]').attr('disabled', true);
                    form.get(0).submit();

                });
            } catch(err) {
                $('.card-errors').text(err.message);
            }
        }
    });

    var helpText = '';
    var offsetTop = '';
    var offsetLeft = '';
    $('.help-me').on('click', function(){

        if( !$(this).hasClass('currentHelp') ) {
            helpText =  $(this).attr('data-help-text');
            $('.help-box p').text(helpText);
            $('.help-box').show();
            $('.help-me').removeClass('currentHelp');
            $(this).toggleClass('currentHelp');
        } else {
            $('.help-box').hide();
            $(this).removeClass('currentHelp');
        }

        if( $('.currentHelp').length ) {
            offsetTop = $('.currentHelp').offset().top - 28 - $('.help-box').height();
            offsetLeft = $('.currentHelp').parent().offset().left;
            $('.help-box').css({
                'top' : offsetTop,
                'left' : offsetLeft,
                'position' : 'absolute',
            });
        }

    });

    $(window).resize(function(){
        if( $('.currentHelp').length ) {
            offsetTop = $('.currentHelp').offset().top - 28 - $('.help-box').height();
            offsetLeft = $('.currentHelp').parent().offset().left;
            $('.help-box').css({
                'top' : offsetTop,
                'left' : offsetLeft,
                'position' : 'absolute',
            });
        }
    });

    $('.close-help').on('click', function(){
        $('.help-box').hide();
        $('.help-me').removeClass('currentHelp');
    });

    $('body').on('click', function() {
        if( !$(event.target).closest('.help-me').length && !$(event.target).closest('.help-box').length ){
            $('.help-box').hide();
            $('.help-me').removeClass('currentHelp');
        }
    });
});